<template>
  <section class="p-6">
    <LayoutPublicHeader :isDark="true" />

    <main class="text-center mt-10">
      <a-button
        @click="goBack"
        class="hidden md:block float-left md:ml-3 border-0 hover:bg-gray-400 bg-gray-500 text-white"
        size="large"
      >
        <template #icon> <ArrowLeftOutlined class="text-white" /></template>
        Atrás
      </a-button>
    </main>

    <div class="container mx-auto">
      <PoliticaPrivacidadDetalle />
    </div>
  </section>
</template>

<script>
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import LayoutPublicHeader from "@/layout/LayoutPublic/LayoutPublicHeader.vue";
import PoliticaPrivacidadDetalle from "./PoliticaPrivacidadDetalle.vue";
import router from "@/router";

export default {
  components: {
    LayoutPublicHeader,
    PoliticaPrivacidadDetalle,
    ArrowLeftOutlined
  },
  setup() {
    const goBack = () => router.back();
    return {
      goBack
    };
  }
};
</script>

<style scoped></style>
